<!--
 * @Description:无牌车支付
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-18 10:40:26
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-nav-bar title="支付选择"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="content">
      <div class="content-text">
        <span>应付金额</span>
      </div>
      <div class="content-number">
        <span class="content-number-number">{{ Number(needPay/100).toFixed(2) }}</span>
        <span>元</span>
      </div>
      <div class="paystyle-text">
        <span>支付方式</span>
      </div>
      <div class="weixin">
        <div class="imgbox">
          <img class="paymentStyle-img"
               src="@/assets/wxParForParking/weixin.png"
               alt="" />
        </div>
        <span class="paymentStyle-text">微信支付</span>
        <span class="paymentStyle-check">
          <van-radio-group v-model="radio">
            <van-radio name="WX"></van-radio>
          </van-radio-group>
        </span>
      </div>
    </div>
    <van-button class="commitPay"
                :loading="commitLoading"
                loading-text="正在支付..."
                @click="handleCommitPay">确认支付</van-button>
  </div>
</template>

<script>
import wx from 'weixin-jsapi'
import $wxConfig from '@/utils/wxConfig.js'
export default {
  data () {
    return {
      commitLoading: false,
      needPay: 0, // 应付金额
      radio: 'WX',
      nonceStr: '', // 随机串
      timeStamp: '', // 时间戳
      sign: ''// 签名
    }
  },
  created () {
    this.needPay = this.$route.query.payMoney
  },

  mounted () {
  },
  methods: {
    // 确认支付按钮
    handleCommitPay () {
      this.commitLoading = true
      // 调起微信支付
      const info = {
        description: '微信支付',
        orderSequence: this.$route.query.payOrder,
        openid: sessionStorage.getItem('openid'),
        amount: Number(this.needPay)
      }
      this.$noPlateNumberPay.jsApiPayNoUserToken(info).then(res => {
        const timestamp = res.resultEntity.timeStamp
        const nonceStr = res.resultEntity.nonceStr
        const packages = res.resultEntity.package
        const signType = res.resultEntity.signType
        const paySign = res.resultEntity.paySign
        this.wxPay(timestamp, nonceStr, packages, signType, paySign)
      }).catch(() => {
        this.commitLoading = false
      })
    },
    // 发起微信支付请求
    wxPay (timestamp, nonceStr, packages, signType, paySign) {
      const self = this
      $wxConfig(['chooseWXPay']).then(() => {
        this.commitLoading = false
        wx.chooseWXPay({
          timestamp: Number(timestamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: function () {
            // 支付成功后的回调函数
            const info1 = {
              deviceId: self.$route.query.deviceId,
              openid: sessionStorage.getItem('openid'),
              orderSequence: self.$route.query.payOrder
            }
            self.$noPlateNumberPay.finishOrder4NoPlate(info1).then(res => {
              self.$toast.success('支付成功')
              window.location.href = 'https://officialaccount.cqybxm.com/#/?page=parkPayForOthers'
              // if (window.WeixinJSBridge) {
              //   window.WeixinJSBridge.call('closeWindow') // 关闭当前页面返回到公众号
              // }
            })
          },
          error: function () {
            self.$toast.fail('支付失败')
            self.commitLoading = false
            self.$router.go(-1)
          }
        })
      }).catch(err => {
        console.log('签名配置失败', err)
        this.commitLoading = false
      })
    },

    // 点击返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/*顶部*/
.top {
  width: 100%;
  height: 44px;
  background: #ffffff;
}
/*顶部返回图片*/
.top-back-img {
  width: 8.5px;
  height: 15px;
  margin-left: 2.5%;
  margin-top: 14.5px;
}
/*顶部返回文字*/
.top-back-text {
  width: 30.5px;
  height: 17.5px;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  margin-left: 2%;
}
/*顶部标题文字*/
.top-title-text {
  width: 30.5px;
  height: 17.5px;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  margin-left: 24%;
}
/*内容部分*/
.content {
  background: #ffffff;
  border-radius: 6px;
  width: 94%;
  margin-left: 3%;
  height: 205px;
  margin-top: 10px;
  padding-top: 35px;
}
/*应付金额文字*/
.content-text {
  /*background-color: red;*/
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.48px;
  width: 100%;
  height: 21px;
  line-height: 21px;
  text-align: center;
}
/*应付金额数字*/
.content-number {
  /*background-color: blue;*/
  width: 100%;
  height: 40px;
  margin-top: 5px;
  text-align: center;
}
.content-number-number {
  font-size: 28px;
  color: #333333;
  letter-spacing: 0;
  line-height: 40px;
}
.paystyle-text {
  width: 100%;
  height: 21px;
  line-height: 21px;
  margin-top: 42px;
  margin-left: 5.6%;
}
/*微信盒子*/
.weixin {
  /*background-color: blue;*/
  width: 100%;
  height: 56px;
  margin-top: 8px;
  display: flex;
}
.imgbox {
  /*background-color: green;*/
  width: 7%;
  height: 24px;
  margin-top: 15px;
  margin-left: 5.6%;
}
/*微信图标*/
.paymentStyle-img {
  width: 24px;
  height: 24px;
}

/*支付文字*/
.paymentStyle-text {
  /*background-color: red;*/
  width: 40%;
  height: 50px;
  line-height: 56px;
  margin-left: 1.9%;
  font-size: 15px;
  color: #666666;
  letter-spacing: 0;
}
/*支付选择*/
.paymentStyle-check {
  position: fixed;
  right: 10%;
  line-height: 56px;
  text-align: center;
  padding-top: 18px;
}
.zhifubao {
  /*background-color: red;*/
  width: 100%;
  height: 56px;
  margin-top: 8px;
  display: flex;
}
/*确认支付按钮*/
.commitPay {
  width: 94%;
  height: 48px;
  margin-left: 3%;
  margin-top: 5%;
  background-color: #4d95f4;
  font-size: 19px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 26px;
  border-radius: 6px;
}
.body {
  overflow-x: hidden;
}
</style>
